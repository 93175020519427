<template>
    <div class="d-flex justify-content-around flex-wrap-reverse" id="contentNotFound">
        <div class="flex-grow-1" id="txtNotFound">
            <div class="flex-column" id="main">
                <h2 id="h1NotFound">Página não encontrada</h2>
                <hr id="hrNotFound" />
                <p id="pNotFound">Parece que a página que você acessou não existe.</p>

                <v-btn id="buttonNotFound"
                       class="text-light"
                       color="#444444"
                       block
                       to="/">Voltar ao início</v-btn>

            </div>
        </div>
        
        <div id="imgNotFound">
            <img src="@/assets/imgs/404.svg" alt="Página não encontrada" />
        </div>

    </div>
</template>

<script>
    export default {
        name: 'NotFound',
        title: 'Página não encontrada - LegisOn',
    }
</script>

<style>
    @import './NotFound.css';
</style>
